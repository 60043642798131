export default defineNuxtRouteMiddleware((to) => {

  // console.log('user.presets');
  // 
  // const ddd = useCookie('preset', {default: () => ({ path: '/' })});
  // ddd.value = { path: 'ddd' };
 
})


